import React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet"


const Reviews = () => {

  return (
  <Layout>
    <Helmet>
      <meta
        http-equiv='refresh'
        content='0; url=/reviews'
        />
    </Helmet>
  </Layout>
  )
};

export default Reviews;
